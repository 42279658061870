import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PriceMobile from "./PriceMobile/PriceMobile";
import "./Price.css";

function Price() {
  const [numTravelers, setNumTravelers] = useState(1);
  const [price, setPrice] = useState(164000);
  const [isMobile, setIsMobile] = useState(false);

  const handleTravelerChange = (e) => {
    const selectedTravelers = Number(e.target.value);
    setNumTravelers(selectedTravelers);
    setPrice(selectedTravelers === 1 ? 164000 : 185000);
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 576);
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  });
  return (
    <section className="price">
      <div className="hotels__title-container">
        <h1 className="hotels__title">СТОИМОСТЬ</h1>
        <div className="hotels__title-line"></div>
      </div>

      <div className="price__content">
        <div className="price__cards">
          <div className="price__card">
            <h2 className="price__card-title">Включено в стоимость</h2>
            <p className="price__card-text text1">
              Встретим и проводим в аэропорт или на железнодорожный вокзал
            </p>
            <p className="price__card-text text2">
              Проживание в отеле 4* в Ессентуках
            </p>
            <p className="price__card-text text3">Завтрак в отеле </p>
            <p className="price__card-text text4">
              Обеды и ужины в ресторанах, включенные в программу тура с винным
              сопровождением и настойками
            </p>
            <p className="price__card-text text5">
              Сопровождение гида на протяжении всего путешествия
            </p>
            <p className="price__card-text text6">Экскурсии </p>
            <p className="price__card-text text7">Дегустации </p>
            <p className="price__card-text text8">Трансфер</p>

            {/* мобильная разметка */}
            <div className="price__mobile">
              <p className="price__paragraph">Не включено в стоимость</p>
              <div className="price__card">
                <p className="price__card-text text9">Авиабилеты</p>
                <p className="price__card-text text10">Курортный сбор</p>
                <p className="price__card-text text11">
                  Личные покупки и сувениры
                </p>
              </div>
              <p className="price__paragraph">Стоимость Тура</p>
              <div className="price__card">
                <div className="price__card-container">
                  <p className="price__card-text text12">
                    При двухместном размещении (на человека)
                  </p>
                  <span className="price__card-full">165 000 ₽</span>
                </div>
                <div className="price__card-container">
                  <p className="price__card-text text12">
                    При одноместном размещении (на человека)
                  </p>
                  <span className="price__card-full">185 000 ₽</span>
                </div>
                <Link to="/payment" className="price-mobile__button">
                  Купить тур
                </Link>
              </div>
            </div>
          </div>

          {/* модальное окно */}
          <div className="price__modal-container">
            <h3 className="price__paragraph">СТОИМОСТЬ ТУРА</h3>
            <div className="price__choice">
              <p className="price__modal-text">НА ЧЕЛОВЕКА</p>
              <div className="price__modal-text-container">
                <h4 className="price__modal-subtitle">
                  при двухместном размещении
                </h4>
                <span className="price__modal-span">165 000 ₽</span>
              </div>
            </div>
            <div className="price__choice">
              <p className="price__modal-text">НА ЧЕЛОВЕКА</p>
              <div className="price__modal-text-container">
                <h4 className="price__modal-subtitle">
                  при одноместном размещении
                </h4>
                <span className="price__modal-span">185 000 ₽</span>
              </div>
            </div>

            <Link to="/payment" className="price__button">
              Купить тур
            </Link>
            <p className="price__not_included">Не включено в стоимость</p>
            <div className="price__not-included-container">
              <p className="price__card-text text9 text-modal">Авиабилеты</p>
              <p className="price__card-text text10 text-modal">
                Личные покупки и сувениры
              </p>
              <p className="price__card-text text11 text-modal">
                Курортный сбор
              </p>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default Price;
