import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Promo.css";
import NavigateButton from "../NavigateButton/NavigateButton";

function Promo() {
  const location = useLocation();
  const isTourPage = location.pathname === "/tours";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="promo">
      <div className="promo__content">
        <h1 className={isTourPage ? "promo__title-tour" : "promo__title"}>
          {isTourPage
            ? "ГАСТРОНОМИЧЕСКИЙ ТУР ПО КАВКАЗСКИМ МИНЕРАЛЬНЫМ ВОДАМ "
            : "ГАСТРОНОМИЧЕСКИЙ ТУР ПО КАВКАЗСКИМ МИНЕРАЛЬНЫМ ВОДАМ "}
        </h1>
        {isTourPage ? (
          <p className="promo__description">03 ЯНВАРЯ — 08 ЯНВАРЯ </p>
        ) : (
          <NavigateButton
            buttonText="Посмотреть маршрут"
            destination="/tours"
          />
        )}
      </div>
      {isTourPage ? (
        <h2 className="promo__tour-text">ПУТЕШЕСТВУЙТЕ С НАМИ</h2>
      ) : (
        <div className="promo__circles-container">
          <div className="promo__circles"></div>
          <h2 className="promo__text">
            Наше путешествие подойдет тем,
            <br /> кто получает удовольствие&nbsp;
            <br />
            от окружающей природы, архитектуры&nbsp;и гастрономии,&nbsp;а также
            ценит комфорт и внимание&nbsp;к деталям
          </h2>
        </div>
      )}
    </section>
  );
}

export default Promo;
