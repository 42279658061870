import React from "react";

function PrivacyPolice () {
	return (
		<section className="privacy-police">
			
		</section>
	)
}

export default PrivacyPolice;