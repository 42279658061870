import React, { useState } from "react";
import "./FormApp.css";
import { useNavigate } from "react-router-dom";

function FormApp() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate(); // Добавлено для навигации

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Проверка заполненности всех полей
    if (
      !formData.name.trim() ||
      !formData.phone.trim() ||
      !formData.email.trim() ||
      !formData.message.trim()
    ) {
      setMessage("Пожалуйста, заполните все поля.");
      return;
    }

    try {
      const response = await fetch("https://gastroandtravel.ru/api/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: formData.name.trim(),
          phone: formData.phone.trim(),
          email: formData.email.trim(),
          message: formData.message.trim(),
        }),
      });

      if (response.ok) {
        setMessage("Заявка успешно отправлена!");
        setFormData({ name: "", phone: "", email: "", message: "" });

        // Переход на страницу благодарности
        navigate("/thanks");
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || "Ошибка при отправке заявки.");
      }
    } catch (error) {
      setMessage("Ошибка подключения к серверу.");
      console.error("Ошибка:", error);
    }
  };

  return (
    <section className="form-app">
      <div className="form-app__container">
        <div className="form-app__content">
          <h1 className="form-app__title">Оставьте предварительную заявку</h1>
          <h2 className="form-app__subtitle">
            Мы свяжемся с Вами и проконсультируем по всем вопросам
          </h2>
          <form className="form-app__form" onSubmit={handleSubmit}>
            <input
              className="form-app__input"
              name="name"
              placeholder="Ваше имя"
              type="text"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              className="form-app__input"
              name="phone"
              placeholder="Телефон"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <input
              className="form-app__input"
              name="email"
              placeholder="Почта"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              className="form-app__input form-app__textarea"
              name="message"
              placeholder="Ваше сообщение"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button className="form-app__button" type="submit">
              Отправить
            </button>
          </form>
          {message && <p className="form-app__message">{message}</p>}
        </div>
      </div>
    </section>
  );
}

export default FormApp;
