import React from "react";


function BurgerButton({
  handleClick,
  isOpen,
  spanCount = 3, // Количество линий
  className = "",
  style = {},
}) {
  return (
    <button
      className={`burger-button ${isOpen ? "open" : ""} ${className}`}
      onClick={handleClick}
      style={{ border: "none" }}
    >
      {[...Array(spanCount)].map((_, index) => (
        <span key={index}></span>
      ))}
    </button>
  );
}

export default BurgerButton;
