import React from "react";
import { Link } from "react-router-dom";
import "./PopupPayment.css";
import telegram from "../../images/tg.svg";
import whatsapp from "../../images/whatsapp.svg";
import Header from "../Header/Header";
import HeaderBar from "../Header/HeaderBar/HeaderBar";

function PopupPayment() {
  return (
    <section className="popup-payment">
      <div className="popup-payment__container">
        <div className="popup-payment__top">
          <h1 className="popup-payment__title">
            Спасибо, ваша заявка отправлена
          </h1>
          <p className="popup-payment__text">
            Мы свяжемся с вами в ближайшее время
          </p>
        </div>
        <div className="popup-payment__content">
          <h2 className="popup-payment__content-title">
            Новый Год <br/> в КавМинВодах
          </h2>
          <p className="popup-payment__content-text">03 ЯНВАРЯ — 08 ЯНВАРЯ</p>
        </div>
        <div className="popup-payment__links">
          <Link
            to="https://wa.me/message/BLBCQRP77FP7E1"
            // className="popup-payment__social-link"
          >
            <img
              className="popup-payment__social-icon"
              src={whatsapp}
              alt="whatsapp"
            />
          </Link>
          <Link
            to="https://t.me/gastroandtravel"
            // className="popup-payment__social-link"
          >
            <img
              className="popup-payment__social-icon"
              src={telegram}
              alt="telegram"
            />
          </Link>
        </div>
        <div className="popup-payment__span-container">
          <span className="popup-payment__span">gas.travel@yandex.ru</span>
          <span className="popup-payment__span">+7 915 432 15 56</span>
        </div>
      </div>
    </section>
  );
}

export default PopupPayment;
