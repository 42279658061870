import React from "react";
import "./Travel.css";

function Travel() {
  return (
    <section className="travel">
      <div className="travel__content">
        <h1 className="travel__title">НАЧНИТЕ СВОЁ ПУТЕШЕСТВИЕ С НАМИ</h1>
        <div className="travel__card card--1">
          <h2 className="travel__card-number">01</h2>
          <p className="travel__card-text">
            Маленькие группы до 12 человек, гарантируют комфортный отдых и
            максимум внимания к каждому
          </p>
        </div>
        <div className="travel__card card--2">
          <h2 className="travel__card-number">02</h2>
          <p className="travel__card-text">
            Транспорт, отели, гиды, экскурсии, еда, напитки уже включены в
            стоимость тура, у нас нет скрытых платежей комиссий
          </p>
        </div>
        <div className="travel__card card--3">
          <h2 className="travel__card-number">03</h2>
          <p className="travel__card-text">
            Мы познакомим Вас с историей региона, самыми интересными
            достопримечательностями, а также местной кухней
          </p>
        </div>
        <div className="travel__card card--4">
          <h2 className="travel__card-number">04</h2>
          <p className="travel__card-text">
            Мы посетим потомков казаков, проживших 250 лет в Турции и
            познакомимся их уникальной культурой и кухней
          </p>
        </div>
        <div className="travel__card card--5">
          <h2 className="travel__card-number">05</h2>
          <p className="travel__card-text">
            На протяжении всего тура Вас будет сопровождать гид. Все
            передвижения проходят на комфортном транспорте
          </p>
        </div>
      </div>
    </section>
  );
}

export default Travel;
