import React from "react";
import { Link } from "react-router-dom";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import { useEffect } from "react";
import { useMenu } from "../../context/MenuContext";
import "./Gallery.css";
import HeaderBar from "../Header/HeaderBar/HeaderBar";
import Footer from "../Footer/Footer";
import foodImages from "../../images/gallery-food/images";
import vineImages from "../../images/gallery-vine/images";
import natureImages from "../../images/gallery-nature/images";
import cultureImages from "../../images/gallery-culture/images";

function Gallery() {
  const [activeCategory, setActiveCategory] = React.useState("food");

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const categoryContent = {
    food: (
      <>
        <h1 className="gallery__title">
          Волшебные виды, аутентичная кухня
          <br /> и кавказское гостеприимство
        </h1>
        <p className="promo__description">СЕВЕРНЫЙ КАВКАЗ</p>
        <h2 className="promo__tour-text">ПУТЕШЕСТВУЙТЕ С НАМИ</h2>
      </>
    ),
    vine: (
      <>
        <h1 className="gallery__title">
          Ощутить вкус кавказской жизни, от зимней обрезки виноградных лоз до
          дегустации «гаражных вин»
        </h1>
        <p className="promo__description">СЕВЕРНЫЙ КАВКАЗ</p>
        <h2 className="promo__tour-text promo__vine">ПУТЕШЕСТВУЙТЕ С НАМИ</h2>
      </>
    ),
    culture: (
      <>
        <h1 className="gallery__title">
          Откройте для себя самобытную и уникальную культуру Кавказа
        </h1>
        <p className="promo__description">СЕВЕРНЫЙ КАВКАЗ</p>
        <h2 className="promo__tour-text promo__culture">
          ПУТЕШЕСТВУЙТЕ С НАМИ
        </h2>
      </>
    ),
    nature: (
      <>
        <h1 className="gallery__title">
          Волшебные виды, аутентичная кухня и кавказское гостеприимство
        </h1>
        <p className="promo__description">СЕВЕРНЫЙ КАВКАЗ</p>
        <h2 className="promo__tour-text promo__nature">ПУТЕШЕСТВУЙТЕ С НАМИ</h2>
      </>
    ),
  };

  const categoryImages = {
    food: foodImages,
    vine: vineImages,
    culture: cultureImages,
    nature: natureImages,
  };

  const { isMenuOpen, handleBurgerClick, handleButtonClick } = useMenu();
  useEffect(() => {
    const galleryContainer = document.querySelector(".gallery__container");
    let masonry;

    const initMasonry = () => {
      const width = window.innerWidth;

      if (masonry) {
        masonry.destroy();
      }

      if (width <= 576 && width >= 375) {
        masonry = new Masonry(galleryContainer, {
          itemSelector: ".gallery__image",
          columnWidth: ".gallery__image",
          gutter: 16,
          fitWidth: true,
          horizontalOrder: true,
        });

        imagesLoaded(galleryContainer, () => {
          masonry.layout();
        });
      }

      if (galleryContainer) {
        galleryContainer.style.height = "auto";
      }

      window.addEventListener("resize", initMasonry);
    };

    initMasonry();

    return () => {
      window.removeEventListener("resize", initMasonry);
      if (masonry) {
        masonry.destroy();
      }
    };
  }, []);
  return (
    <section className="gallery">
      <div className={`gallery__content gallery__content-${activeCategory}`}>
        <HeaderBar
          isMenuOpen={isMenuOpen}
          handleBurgerClick={handleBurgerClick}
          handleButtonClick={handleButtonClick}
        />
        {categoryContent[activeCategory]}
      </div>
      <div className="gallery__buttons-bar">
        <button
          className={`gallery__button ${
            activeCategory === "food" ? "gallery__button-active" : ""
          }`}
          onClick={() => handleCategoryChange("food")}
        >
          Еда
        </button>
        <button
          className={`gallery__button ${
            activeCategory === "vine" ? "gallery__button-active" : ""
          }`}
          onClick={() => handleCategoryChange("vine")}
        >
          Вино
        </button>
        <button
          className={`gallery__button ${
            activeCategory === "culture" ? "gallery__button-active" : ""
          }`}
          onClick={() => handleCategoryChange("culture")}
        >
          Культура
        </button>
        <button
          className={`gallery__button ${
            activeCategory === "nature" ? "gallery__button-active" : ""
          }`}
          onClick={() => handleCategoryChange("nature")}
        >
          Природа
        </button>
      </div>
      <div className="gallery__container">
        {categoryImages[activeCategory].map((image, index) => (
          <img
            key={index}
            className={`gallery__image ${activeCategory}${index + 1}`}
            src={image}
            alt="nature"
          />
        ))}
      </div>
      <div className="gallery__button-container">
        <Link to="/payment" className="gallery__button-buy">
          Купить тур
        </Link>
      </div>
      <Footer />
    </section>
  );
}

export default Gallery;
