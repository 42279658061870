import React, { useState, useEffect } from "react";
import "./Payment.css";
import "../Contacts/Contacts.css";
import { Link, useNavigate } from "react-router-dom";
import HeaderBar from "../Header/HeaderBar/HeaderBar";
import { useMenu } from "../../context/MenuContext";
import telegram from "../../images/tg.svg";
import instagram from "../../images/Instagram.svg";
import whatsapp from "../../images/whatsapp.svg";

function Payment() {
  const { isMenuOpen, handleBurgerClick, handleButtonClick } = useMenu();
  const navigate = useNavigate(); // Добавлено для навигации

  // Инициализация состояния формы
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);

  // Валидация формы
  useEffect(() => {
    setIsFormValid(
      formData.name.trim() &&
        formData.phone.trim() &&
        formData.email.trim() &&
        formData.message.trim()
    );
  }, [formData]);

  // Обработка изменений в полях формы
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Обработка отправки формы
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid) {
      console.log("Форма недействительна, данные не отправлены");
      return;
    }

    console.log("Отправка данных:", formData);

    try {
      const response = await fetch("https://gastroandtravel.ru/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.name.trim(),
          phone: formData.phone.trim(),
          email: formData.email.trim(),
          message: formData.message.trim(),
        }),
      });

      console.log("Ответ сервера:", response);

      if (response.ok) {
        const result = await response.text();
        console.log("Сообщение отправлено успешно:", result);
        setFormData({ name: "", phone: "", email: "", message: "" });

        // Переход на страницу благодарности
        navigate("/thanks");
      } else {
        const errorDetails = await response.text();
        console.error("Ошибка ответа сервера:", errorDetails);
        alert("Ошибка при отправке сообщения: " + errorDetails);
      }
    } catch (error) {
      console.error("Ошибка при отправке сообщения:", error.message);
      alert(
        "Произошла ошибка при отправке. Пожалуйста, проверьте введённые данные и попробуйте снова."
      );
    }
  };

  return (
    <section className="payment">
      <HeaderBar
        isMenuOpen={isMenuOpen}
        handleBurgerClick={handleBurgerClick}
        handleButtonClick={handleButtonClick}
      />
      <div className="payment__container">
        <div className="payment__text-container">
          <h1 className="payment__title">ОПЛАТА</h1>
          <div className="contacts__description-container">
            <p className="contacts__text payment-text">
              Укажите по какому номеру с вами можно связаться - мы подробно
              проконсультируем и запишем вас на тур!
            </p>
            <p className="contacts__text payment-text">
              Гарантируем, что рассылать спам и навязчивую рекламу не будем!
            </p>
            <p className="contacts__text payment-text">
              Или напишите нам в любой удобный для вас мессенджер:
            </p>
          </div>
          <div className="contacts__links-container">
            <div className="contacts__card">
              <a
                href="https://wa.me/message/BLBCQRP77FP7E1"
                className="contacts__social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="contacts__social-icon"
                  src={whatsapp}
                  alt="whatsapp"
                />
              </a>
            </div>
            <div className="contacts__card">
              <a
                href="https://t.me/gastroandtravel"
                className="contacts__social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="contacts__social-icon"
                  src={telegram}
                  alt="telegram"
                />
              </a>
            </div>
            <div className="contacts__card">
              <a
                href="https://instagram.com"
                className="contacts__social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="contacts__social-icon"
                  src={instagram}
                  alt="instagram"
                />
              </a>
            </div>
          </div>
          <p className="contacts__text middle-text payment-text">
            gas.travel@yandex.ru
          </p>
          <p className="contacts__text payment-text">+7 916 940 06 91</p>
        </div>
        <form className="payment__form" onSubmit={handleSubmit}>
          <label className="contacts__label payment__label">Имя</label>
          <input
            className="contacts__input payment__input"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <label className="contacts__label payment__label">Телефон</label>
          <input
            className="contacts__input payment__input"
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <label className="contacts__label payment__label">Почта</label>
          <input
            className="contacts__input payment__input"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <label className="contacts__label payment__label">Сообщение</label>
          <textarea
            className="contacts__input payment__textarea"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>

          <div className="contacts__checkbox">
            <input
              className="contacts__input-checkbox payment__input-checkbox"
              type="checkbox"
              required
            />
            <label className="contacts__checkbox-label payment__checkbox-label">
              Я прочитал и принимаю
              <Link
                to="/privacy-policy"
                className="contacts__link payment__link"
              >
                Политику конфиденциальности
              </Link>
            </label>
          </div>
          <button type="submit" className="promo__big-button">
            Продолжить
          </button>
        </form>
      </div>
    </section>
  );
}

export default Payment;
