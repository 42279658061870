import React from "react";
import "./Hotels.css";
import guide from "../../images/guid.jpeg";

function Hotels() {
  return (
    <section className="hotels">
      <div className="hotels__title-container">
        <h1 className="hotels__title">ОТЕЛЬ</h1>
        <div className="hotels__title-line"></div>
      </div>

      <div className="hotels__content">
        <img
          className="hotels__image"
          src={"https://i.postimg.cc/rp1bQN37/4.png"}
          alt="hotel"
        />
        <div className="hotels__card">
          <div className="hotels__text-container">
            <h2 className="hotels__card-title">
              Отель Курортный 4*, Ессентуки
            </h2>
            <p className="hotels__card-text">
              Превосходный отель, где гостеприимство Кавказа гармонично
              сочетается с непревзойденным сервисом. Отель расположен в самом
              центре города в тихом районе вблизи от главных
              достопримечательностей
            </p>
            <p className="hotels__card-text">
              К услугам гостей бар, сигарный клуб для мужчин, терраса с
              великолепным видом на город, тренажерный зал, спа —комплекс,
              бесплатный wi-fi. В шаговой доступности питьевые нарзанные галереи
              №4/17
            </p>
            <p className="hotels__card-text">
              В распоряжении гостей кондиционер, журнальный столик, чайник, фен,
              халаты, косметические средства, сейф, телевизор с плоским экраном,
              а из номеров открывается прекрасный панорамный вид на исторический
              центр города, курортный парк и знаменитые горы
            </p>
          </div>
        </div>
      </div>
      <div className="hotels__title-container">
        <h1 className="hotels__title">ГИД</h1>
        <div className="hotels__title-line"></div>
      </div>

      <div className="hotels__content">
        <img className="hotels__image guide-photo" src={guide} alt="hotel" />
        <div className="hotels__card">
          <div className="hotels__text-container">
            <h2 className="hotels__card-title">Наш гид Анна</h2>
            <p className="hotels__card-text">
              В путешествии нас будет сопровождать Анна, которая влюбляет в
              регион уже больше 10 лет, является ТОП-3 гидов по Кавминводам.
              Анна снималась для телеканалов Культура и СТС
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hotels;
