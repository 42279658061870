import React, { useEffect } from "react";
import {Link} from "react-router-dom";
import "./Description.css";

import video1 from "../../videos/video1.mp4";
import video2 from "../../videos/video2.mp4";

function Description() {
  useEffect(() => {
    const wrapper = document.querySelector(".description__cards");
    let pressed = false;
    let startX = 0;

    const handleMouseDown = (event) => {
      pressed = true;
      startX = event.clientX;
     
    };

    const handleMouseLeave = () => {
      pressed = false;
    };

    const handleMouseUp = () => {
      pressed = false;
      // wrapper.style.cursor = "grab";
    };

    const handleMouseMove = (event) => {
      if (!pressed) return;
      wrapper.scrollLeft += startX - event.clientX;
      startX = event.clientX;
    };

    wrapper.addEventListener("mousedown", handleMouseDown);
    wrapper.addEventListener("mouseleave", handleMouseLeave);
    window.addEventListener("mouseup", handleMouseUp);
    wrapper.addEventListener("mousemove", handleMouseMove);

    return () => {
      wrapper.removeEventListener("mousedown", handleMouseDown);
      wrapper.removeEventListener("mouseleave", handleMouseLeave);
      window.removeEventListener("mouseup", handleMouseUp);
      wrapper.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <section className="description">
      <h1 className="description__title">МИНЕРАЛЬНЫЕ ВОДЫ</h1>
      <h2 className="description__text">
        Мы посетим самые интересные и аутентичные места. Нас ждут четыре
        города—курорта, винодельни, сыроварня, рестораны кавказкой и авторской
        кухни, а также минеральные источники и головокружительные панорамы
        Кавказских Минеральных Вод и окрестностей
      </h2>
      <div className="description__cards">
        {/* card 1 */}
        <div className="description__card">
          <div className="description__image card-1"></div>
          <p className="description__date date-1">
            ТУР НА НОВОГОДНИЕ ПРАЗДНИКИ
          </p>
          <span className="description__card-text">
            Винзавод со&nbsp;120-ти летней историей, винотека с&nbsp;собранием
            уникальных вин, произве&shy;дённых за&nbsp;всю его&nbsp;историю,
            и,&nbsp;конечно, дегустация
          </span>
          <Link to="/tours" className="description__button">
            <span>Подробнее</span>
          </Link>
        </div>
        {/* card 2 */}
        <div className="description__card card-2">
          <p className="description__date">03 ЯНВАРЯ — 08 ЯНВАРЯ</p>
          <span className="description__card-text">
            Лучшие рестораны, отель 4* в центре города, винодельни и сыроварня,
            один из лучших гидов КавМинВод, мини—группа, всё включено{" "}
          </span>

          <Link to="/tours" className="description__button">
            <span>Подробнее</span>
          </Link>
          <div className="description__video-wrapper">
            <video
              className="description__video"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={video1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        {/* card 3 */}
        <div className="description__card">
          <div className="description__image card-3"></div>
          <p className="description__date">КАВМИНВОДЫ</p>
          <span className="description__card-text">
            Горные водопады, высокие скалы, природа живописного каньона
            и&nbsp;захватывающие виды Кавказских Минеральных
            Вод&nbsp;и&nbsp;Карачаево-Черкесии 
          </span>
          <Link to="/tours" className="description__button">
            <span>Подробнее</span>
          </Link>
        </div>
        {/* card 4 */}
        <div className="description__card card-4">
          <p className="description__date">КАРАЧАЕВО — ЧЕРКЕСИЯ</p>
          <span className="description__card-text">
            Рестораны с&nbsp;авторской и&nbsp;кавказской кухней, тематическое
            кафе с&nbsp;атмосферой светских вечеров XIX&nbsp;века, посвящённое
            Лермонтову
          </span>

          <Link to="/tours" className="description__button">
            <span>Подробнее</span>
          </Link>
          <div className="description__video-wrapper">
            <video
              className="description__video"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={video2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <h3 className="description__second-text">
        В&nbsp;нашем туре всё продумано до&nbsp;мелочей: от&nbsp;виноделен
        и&nbsp;сыроварен до&nbsp;авторских ресторанов и&nbsp;комфортных
        гостиниц. Вы&nbsp;с&nbsp;лёгкостью можете отправиться
        в&nbsp;одиночестве, так&nbsp;как в&nbsp;нашем туре вас ждут люди
        со&nbsp;схожими интересами
      </h3>
    </section>
  );
}

export default Description;
