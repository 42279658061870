import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import ContactForm from "../ContactForm/ContactForm";
import telegram from "../../images/tg.svg";
import instagram from "../../images/Instagram.svg";
import whatsapp from "../../images/whatsapp.svg";

function Footer() {
  const [isMobile, setIsMobile] = useState(false);
  const [subscriptionMessage, setSubscriptionMessage] = useState("");

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const handleFooterSubmit = async (data) => {
    try {
      const response = await fetch("http://localhost:3001/api/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: data.email }), // Только email для подписки
      });

      if (response.ok) {
        alert("Подписка успешна!");
      } else {
        alert("Ошибка при подписке.");
      }
    } catch (error) {
      alert("Ошибка подключения.");
    }
  };

  const handleSubscriptionSubmit = async (formData) => {
    try {
      const response = await fetch("http://localhost:3001/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "Подписчик",
          email: formData.email,
          phone: "Не указан",
          message: "Запрос на подписку на рассылку",
        }),
      });

      if (response.ok) {
        setSubscriptionMessage("Подписка успешно оформлена!");
      } else {
        setSubscriptionMessage("Ошибка при оформлении подписки.");
      }
    } catch (error) {
      setSubscriptionMessage("Ошибка при подключении к серверу.");
      console.error("Ошибка:", error);
    }
  };

  return (
    <section className="footer">
      <div className="footer__container">
        <div className="footer__links">
          <a
            href="/contacts"
            className="footer__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            КОНТАКТЫ
          </a>
          <a
            href="/partnership"
            className="footer__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            СОТРУДНИЧЕСТВО
          </a>
          <a
            href="/faq"
            className="footer__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ
          </a>
          <a
            href="/terms"
            className="footer__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            ПРАВИЛА & УСЛОВИЯ
          </a>
          {isMobile && (
            <a
              href="/terms"
              className="footer__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
            </a>
          )}
        </div>
        <div className="footer__content">
          <h1 className="footer__logo">Gastro Traveller</h1>
          {subscriptionMessage && (
            <p className="subscription-message">{subscriptionMessage}</p>
          )}
        </div>

        <div className="footer__social">
          <span className="footer__copyright">© 2024 Gastro Traveller</span>
          <Link href="/terms" className="footer__confidentiality">
            Политика конфиденциальности
          </Link>
          <div className="footer__social-links">
            <Link
              to="https://wa.me/message/BLBCQRP77FP7E1"
              className="footer__social-link"
            >
              <img
                className="footer__social-icon"
                src={whatsapp}
                alt="whatsapp"
              />
            </Link>
            <Link
              to="https://t.me/gastroandtravel"
              className="footer__social-link"
            >
              <img
                className="footer__social-icon"
                src={telegram}
                alt="telegram"
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
