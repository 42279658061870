// src/images/gallery-nature/images.js
import nature1 from "./nature1.png";
import nature2 from "./nature2.png";
import nature3 from "./nature3.png";
import nature4 from "./nature4.png";
import nature5 from "./nature5.png";
import nature6 from "./nature6.png";
import nature7 from "./nature7.png";
import nature8 from "./nature8.png";
import nature9 from "./nature9.png";
import nature10 from "./nature10.png";
import nature11 from "./nature11.png";
import nature12 from "./nature12.png";
import nature13 from "./nature13.png";
import nature14 from "./nature14.png";
import nature15 from "./nature15.png";
import nature16 from "./nature16.png";
import nature17 from "./nature17.png";
import nature18 from "./nature18.png";
// Экспортируем массив с изображениями
const natureImages = [
  nature1, nature2, nature3, nature4, nature5, nature6, nature7, nature8, nature9,
  nature10, nature11, nature12, nature13, nature14, nature15, nature16, nature17, nature18
];

export default natureImages;