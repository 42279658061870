import React, {useEffect} from "react";
import "./SheduleDesctop.css";
import Price from "../Price/Price";
import Hotels from "../Hotels/Hotels";

function SheduleDesctop() {
	useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="shedule-desctop">
      <div className="shedule-desctop__card-container">
        <img
          className="shedule-desctop__image image1"
          src={"https://i.postimg.cc/MTtL8KWD/3.png"}
          alt="shedule"
        />
        <div className="shedule-desctop__card-text-container">
          <h2 className="shedule-desctop__card-title">1 ДЕНЬ</h2>
          <h2 className="shedule-desctop__card-text">
            Встречаем Вас в аэропорту или на железнодорожном вокзале Минеральных
            вод
          </h2>
          <h2 className="shedule-desctop__card-text">
            Вечером совершим небольшую прогулку по Курортному бульвару
            Кисловодска, увидим исторические здания, Нарзанную галерею в стиле
            рыцарского замка и здание главных Нарзанных ванн, построенных в
            мавританском стиле. Выезд из отеля около 17:00
          </h2>
          <h2 className="shedule-desctop__card-text">
            На ужин заглянем в самое сердце курорта, насладимся авторской
            интерпретацией знакомых ингредиентов с необычными гастро—акцентами,
            сырами из собственной сыроварни, настойками собственного
            производства и вином
          </h2>
          <h2 className="shedule-desctop__card-text">
            После ужина едем отдыхать в наш отель в Ессентуках
          </h2>
        </div>
      </div>
      <div className="shedule-desctop__card-container">
        <div className="shedule-desctop__card-text-container">
          <h2 className="shedule-desctop__card-title">2 ДЕНЬ</h2>
          <h2 className="shedule-desctop__card-text">Завтракаем в отеле</h2>
          <h2 className="shedule-desctop__card-text">
            Отправляемся на гору Машук, с которой открывается захватывающая дух
            панорама предгорий Кавказа и Большого Кавказского хребта с Эльбрусом
            и Казбеком, а также панорама Пятигорска и его окрестностей.
            Прогуляемся по Пятигорску, узнаем о живших здесь знаменитых людях,
            посетим бюветы Пятигорска, где попробуем минеральную воду, а также
            знаменитый Провал и место дуэли Лермонтова. Вы думали, что знаете о
            Пятигорске все? Наш гид сможет Вас удивить
          </h2>
          <h2 className="shedule-desctop__card-text">
            На обед мы едем в местную сыроварню, где для нас проведут дегустацию
            сыров под бокал вина. Мы попробуем фондю с хлебными гренками,
            фруктами и миксом закусок, пиццу с нестандартными сочетаниями, а
            также крамбл с кремом из рикотты
          </h2>
          <h2 className="shedule-desctop__card-text">
            Далее мы посетим городскую винодельню с высококачественным
            оборудованием, позволяющим оптимально обрабатывать виноград при
            сохранении его целостности. В 2012—2016 годах первые произведенные
            здесь вина завоевали медали на конкурсе «Кубок Гаражистов». Мы
            познакомимся с владелицей винодельни, которая проведет для нас
            дегустацию вин из винограда сортов Каберне Фран, Каберне Совиньон и
            Саперави
          </h2>
          <h2 className="shedule-desctop__card-text">
            На ужин отправимся в лермонтовские времена, где окунемся в мир живой
            музыки и насладимся старорусской кухней, попробуем перепелку с
            ореховой посыпкой и чечевичным пюре, гусарскую кашу, пирожки «с
            опилками» по рецепту 19 века и гусарскую жженку — напиток, которым
            когда-то отмечали посвящение в гусары
          </h2>
          <h2 className="shedule-desctop__card-text">
            Возвращаемся в наш отель и отдыхаем
          </h2>
        </div>
        <img
          className="shedule-desctop__image image2"
          src={"https://i.postimg.cc/R0wd6qQv/Rectangle-692.png"}
          alt="shedule"
        />
      </div>
      <div className="shedule-desctop__card-container">
        <img
          className="shedule-desctop__image image3"
          src={"https://i.postimg.cc/CKgrBf57/10.png"}
          alt="shedule"
        />
        <div className="shedule-desctop__card-text-container">
          <h2 className="shedule-desctop__card-title">3 ДЕНЬ</h2>
          <h2 className="shedule-desctop__card-text">Завтракаем в отеле</h2>
          <h2 className="shedule-desctop__card-text">
            Экскурсия по Ессентукам, где во время прогулки по тенистым аллеям
            парка услышим увлекательные истории связанные с городом, узнаем, что
            такое механотерапия, конечно, попробуем минеральную воду и посетим
            знаменитую грязелечебницу Семашко
          </h2>
          <h2 className="shedule-desctop__card-text">
            Насладимся обедом в местном ресторане с панорамным видом на город и
            попробуем вкуснейшие хычины
          </h2>
          <h2 className="shedule-desctop__card-text">
            Далее едем на одно из крупнейших винодельческих предприятий
            Ставрополья с более чем вековой историей, где нас познакомят с
            историей завода, проведут по старинным подвалам, хранящим на
            выдержке благородные напитки и поделятся секретами производства. Мы
            попробуем коньяки и вина, часть из которых обычно не подаются на
            дегустациях
          </h2>
          <h2 className="shedule-desctop__card-text">
            Вечер завершим ужином в ресторане-пивоварне, попробуем несколько
            сортов пива, сваренного по классическим чешским рецептам и, конечно,
            фирменные пивные закуски
          </h2>
        </div>
      </div>
      <div className="shedule-desctop__card-container">
        <div className="shedule-desctop__card-text-container">
          <h2 className="shedule-desctop__card-title">4 ДЕНЬ</h2>
          <h2 className="shedule-desctop__card-text">Завтракаем в отеле</h2>
          <h2 className="shedule-desctop__card-text">
            После завтрака едем в семью, где познакомимся с бытом и историей
            донских казаков, проживших около 250 лет на чужбине.
            Казаки–Некрасовцы являются удивительным сплавом старообрядческих
            русских обычаев и османского колорита. Интересно, что этот особый
            восточный колорит сохраняется несмотря на то, что они вернулись на
            Родину более полвека назад. Мы узнаем, почему Некрасовцы не
            возвращались в царскую Россию, зачем кофе нужно подавать в постель,
            поиграем в казачий боулинг, а также отведаем традиционные блюда
            казаков под наливки и настойки
          </h2>
          <h2 className="shedule-desctop__card-text">
            Узнав все секреты казаков, мы едем на семейную винодельню. Сама
            винодельня и хранилище вин довольно компактные, но они расположены в
            старинном здании с винным погребом, построенным в 19 веке немецкими
            колонистами. Виноградники расположены на 45-й параллели – той же
            широте, на которой находятся знаменитые винные регионы Бордо, Венето
            и Пьемонт, а земля соответствует категории Гран Крю (превосходный
            участок). Мы увидим все процессы производства, соответствующие
            современным требованиям виноделия, а завершим наш вечер шашлыками и
            дегустацией авторских вин
          </h2>
          <h2 className="shedule-desctop__card-text">
            После экскурсии заглянем в винотеку и попробуем продукцию старейшего
            завода настоек, бальзамов, ароматных спиртов и водок, история
            которого начинается с 1868 года. Нам расскажут об истории завода, а
            также проведут дегустацию крепких спиртных напитков
          </h2>
          <h2 className="shedule-desctop__card-text">
            После виноделен поужинаем в атмосферном ресторане кавказской кухни с
            интересной историей и традицией
          </h2>
        </div>
        <img
          className="shedule-desctop__image image4"
          src={"https://i.postimg.cc/Z5S2fk7Y/Rectangle-738.png"}
          alt="shedule"
        />
      </div>
      <div className="shedule-desctop__card-container">
        <img
          className="shedule-desctop__image image5"
          src={"https://i.postimg.cc/bvG5rzdT/Rectangle-744.png"}
          alt="shedule"
        />
        <div className="shedule-desctop__card-text-container">
          <h2 className="shedule-desctop__card-title">5 ДЕНЬ</h2>
          <h2 className="shedule-desctop__card-text">Завтракаем в отеле</h2>
          <h2 className="shedule-desctop__card-text">
            Едем к природному памятнику — скальное образование на окраине
            Кисловодска. Он представляет собой мыс одного из отрогов
            Боргустанского хребта, абсолютная высота которого 871 м. В
            результате выветривания и эрозии крайняя пещера мыса стала сквозной
            и по форме напоминает гигантское «кольцо» диаметром 8—10 м. Отсюда
            открывается прекрасная панорама Кисловодска и его окрестностей,
            Пятигорья, Джинальского хребта, а в хорошую погоду виден и Эльбрус
          </h2>
          <h2 className="shedule-desctop__card-text">
            Далее нас ждет красивое горное ущелье в окружении доломитовых пещер,
            отвесных скал и живописных лугов. Водопады буквально спрятаны в
            узкой теснине реки, которая каньоном рассекает Скалистый хребет
            Северного Кавказа. Насладимся кавказским гостеприимством и отведаем
            хычины, жар—баур, шашлык из черного карачаевского барашка и местное
            живое пиво с видом на горы
          </h2>
          <h2 className="shedule-desctop__card-text">
            Зарядившись позитивными эмоциями и впечатления, вернемся обратно в
            Кисловодск, город—курорт, известный еще с царских времен.
            Прогуляемся по терренкуру национального парка —главной
            достопримечательности города, увидим старинную застройку центра
            города и знаменитую колоннаду
          </h2>
          <h2 className="shedule-desctop__card-text">
            На ужин заглянем в настоящий сказочный лес с цветочной рекой и
            скалистыми стенами, где познакомимся с кухней Кавказа в новой
            интерпретации и удивимся оригинальной подаче напитков и блюд
          </h2>
        </div>
      </div>
      <div className="shedule-desctop__card-container">
        <div className="shedule-desctop__card-text-container">
          <h2 className="shedule-desctop__card-title">6 ДЕНЬ</h2>
          <h2 className="shedule-desctop__card-text">Завтракаем в отеле</h2>
          <h2 className="shedule-desctop__card-text">
            Экскурсия в самый маленький из городов КавМинВод, который расположен
            в лесном массиве. Мы узнаем, как складывалась история курорта, про
            дачу эмира Бухары, Лермонтовский, Славяновский и Смирновский бюветы
          </h2>
          <h2 className="shedule-desctop__card-text">
            Пообедаем в ресторане на Курортном озере в сопровождении вин и
            настоек. Завершим обед фирменным десертом, вкус и вид которого
            никого не оставит равнодушным
          </h2>

          <h2 className="shedule-desctop__card-text">
            Мы проводим Вас в аэропорт или на железнодорожный вокзал Минеральных
            Вод после 16:00. И будем ждать Вас в новом путешествии
          </h2>
        </div>
        <img
          className="shedule-desctop__image image6"
          src={"https://i.postimg.cc/1RWDCDv8/Rectangle-745.png"}
          alt="shedule"
        />
      </div>
      <Hotels />
      <Price />
    </section>
  );
}

export default SheduleDesctop;
