// src/images/gallery-culture/images.js
import culture1 from "./culture1.png";
import culture2 from "./culture2.png";
import culture3 from "./culture3.png";
import culture4 from "./culture4.png";
import culture5 from "./culture5.png";
import culture6 from "./culture6.png";
import culture7 from "./culture7.png";
import culture8 from "./culture8.png";
import culture9 from "./culture9.png";
import culture10 from "./culture10.png";
import culture11 from "./culture11.png";
import culture12 from "./culture12.png";
import culture13 from "./culture13.png";
import culture14 from "./culture14.png";
import culture15 from "./culture15.png";
import culture16 from "./culture16.png";
import culture17 from "./culture17.png";
import culture18 from "./culture18.png";

// Экспортируем массив с изображениями
const cultureImages = [
  culture1, culture2, culture3, culture4, culture5, culture6, culture7, culture8, culture9,
  culture10, culture11, culture12, culture13, culture14, culture15, culture16, culture17, culture18
];

export default cultureImages;