import React, { useState } from "react";
import "./BookingMobile.css";
import video3 from "../../../videos/video3.mp4";

function BookingMobile() {
  const [activeButton, setActiveButton] = useState(1);
  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };

  const renderContent = () => {
    switch (activeButton) {
      case 1:
        return (
          <>
            <h2 className="booking-mobile__subtitle">Забронировать тур</h2>
          </>
        );
      case 2:
        return (
          <>
            <h2 className="booking-mobile__subtitle">Быстрая оплата</h2>
          </>
        );
      case 3:
        return (
          <>
            <h2 className="booking-mobile__subtitle">
              Встречаем Вас в аэропорту
            </h2>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <section className="booking-mobile">
      <div className="booking-mobile__video-container">
        <video
          className="booking-mobile__video"
          autoPlay
          loop
          muted
          playsInline
        >
          <source
            className="booking-mobile__video-source"
            src={video3}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
				
      </div>

      <div className="booking-mobile__text-container">
        <h1 className="booking-mobile__title">
          ОТ БРОНИРОВАНИЯ К ПУТЕШЕСТВИЮ В ОДИН КЛИК
        </h1>
        {renderContent()}
        <p className="booking-mobile__text">
          Мы свяжемся с Вами и ответим на все интересующие Вас вопросы
        </p>
      </div>
      <div className="booking-mobile__buttons">
        <button
          className={`booking-mobile__button ${
            activeButton === 1 ? "active" : ""
          }`}
          type="button"
						onClick={() => handleButtonClick(1)}
						/>
        <button
          className={`booking-mobile__button ${
						activeButton === 2 ? "active" : ""
          }`}
          type="button"
					onClick={() => handleButtonClick(2)}
					/>
        <button
          className={`booking-mobile__button ${
						activeButton === 3 ? "active" : ""
          }`}
					onClick={() => handleButtonClick(3)}
          type="button"
        />
      </div>
      <h3 className="booking-mobile__second-text">
        Круглогодичные развлечения,  захватывающие дух пейзажи, непревзойденная
        кухня и кавказский колорит — Кавказские Минеральные Воды воплощают в
        себе все, что Вы ищите для отдыха
      </h3>
    </section>
  );
}

export default BookingMobile;
