import React, { useState } from "react";
import "./Booking.css";
import video3 from "../../videos/video3.mp4";

function Booking() {
  const [activeCard, setActiveCard] = useState(1);

  // const handleButtonClick = (cardIndex) => {
  //   console.log(`Button ${cardIndex} clicked`);
  //   setActiveCard(cardIndex);
  // };

  return (
    <section className="booking">
      <div className="booking__container">
        <div className="booking__text-wrapper">
          <h1 className="booking__title">
            ОТ БРОНИРОВАНИЯ К ПУТЕШЕСТВИЮ В ОДИН КЛИК
          </h1>
          <div className="booking__card-container">
            <div className="booking__card">
              <h2 className="booking__card-title">Забронировать тур</h2>
              <p className="booking__card-text">
                Мы свяжемся с Вами и ответим на все интересующие Вас вопросы
              </p>
            </div>

            <div className="booking__card">
              <h2 className="booking__card-title">Быстрая оплата</h2>
              <p className="booking__card-text">
                Мы пришлем Вам ссылку и договор на оплату для подтверждения тура
              </p>
            </div>

            <div className="booking__card">
              <h2 className="booking__card-title">Встречаем Вас в аэропорту</h2>
              <p className="booking__card-text">
                Мы встречаем Вас в аэропорту, откуда начнется наше увлекательное
                путешествие
              </p>
            </div>
          </div>
        </div>
        <div className="booking__video-wrapper">
          <video className="booking__video" autoPlay loop muted>
            <source src={video3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <p className="booking__paragraph">
        Круглогодичные развлечения, захватывающие дух пейзажи, непревзойденная
        кухня и кавказский колорит — Кавказские Минеральные Воды воплощают в
        себе все, что Вы ищите для отдыха
      </p>
    </section>
  );
}

export default Booking;
