import React, { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { MenuProvider } from "../../context/MenuContext";
import Header from "../Header/Header.js";
import Description from "../Description/Description.js";
import Travel from "../Travel/Travel.js";
import Booking from "../Booking/Booking.js";
import BookingMobile from "../Booking/BookingMobile/BookingMobile";
import Shedule from "../Schedule/Schedule";
import SheduleDesctop from "../SheduleDesctop/SheduleDesctop";
import Photos from "../Photos/Photos.js";
import Footer from "../Footer/Footer.js";
import TravelMobile from "../Travel/TravelMobile/TravelMobile";
import Contacts from "../Contacts/Contacts";
import Partnership from "../Contacts/Partnership/Partnership";
import FAQ from "../Information/FAQ/FAQ";
import Terms from "../Information/Terms/Terms";
import PrivacyPolicy from "../Information/PrivacyPolicy/PrivacyPolicy";
import Gallery from "../Gallery/Gallery";
import GalleryDesctop from "../GalleryDesctop/GalleryDesctop";
import Payment from "../Payment/Payment";
import Hotels from "../Hotels/Hotels";
import FormApp from "../FormApp/FormApp";
import PopupPayment from "../PopupPayment/PopupPayment";
import HeaderBar from "../Header/HeaderBar/HeaderBar";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <MenuProvider>
      <div className="app">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Description />
                {isMobile ? <TravelMobile /> : <Travel />}
                {isMobile ? <BookingMobile /> : <Booking />}
                <Photos />
                <FormApp />
                <Footer />
              </>
            }
          />
          <Route
            path="/tours"
            element={
              <>
                <Header />
                {isMobile ? <Shedule /> : <SheduleDesctop />}
                {/* <Hotels /> */}
                <Footer />
              </>
            }
          />
          <Route
            path="/gallery"
            element={
              <>
                {isMobile ? <Gallery /> : <GalleryDesctop />}
                <Footer />
              </>
            }
          />
          <Route path="/contacts" element={<Contacts />} />
          <Route
            path="/payment"
            element={
              <>
                <Payment />
              </>
            }
          />
          <Route
            path="/thanks"
            element={
              <>
								<HeaderBar />
                <PopupPayment />
              </>
            }
          />
          <Route path="/partnership" element={<Partnership />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </MenuProvider>
  );
}

export default App;
