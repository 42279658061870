import React, { useEffect } from "react";
import NavigateButton from "../NavigateButton/NavigateButton";
import "./Photos.css";

import img1 from "../../images/photos1.png";
import img2 from "../../images/photos2.png";
import img3 from "../../images/photos3.png";
import img4 from "../../images/photos4.png";
import img5 from "../../images/photos5.png";
import img6 from "../../images/photos6.png";

function Photos() {
  useEffect(() => {
    // const wrapper = document.querySelector(".description__cards");
    // let pressed = false;
    // let startX = 0;
  });
  return (
    <section className="photos">
      <h1 className="photos__title">#ФОТО ИЗ НАШЕГО ПОСЛЕДНЕГО ПУТЕШЕСТВИЯ</h1>
      <div className="photos__wrapper">
        <div className="photos__container">
          <img className="photos__img" src={img1} alt="Фото из последего путешествия" />
          <img className="photos__img" src={img2} alt="Фото из последего путешествия" />
          <img className="photos__img" src={img3} alt="Фото из последего путешествия" />
          <img className="photos__img" src={img4} alt="Фото из последего путешествия" />
          <img className="photos__img" src={img5} alt="Фото из последего путешествия" />
          <img className="photos__img" src={img6} alt="Фото из последего путешествия" />
        </div>
      </div>
      <NavigateButton
        buttonText="Посмотреть маршрут"
        destination="/tours"
      />
    </section>
  );
}

export default Photos;
