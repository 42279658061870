import React, { useState } from "react";
import { Link } from "react-router-dom";
import HeaderBar from "../Header/HeaderBar/HeaderBar";
import Footer from "../Footer/Footer";
import "./GalleryDesctop.css";
import foodImages from "../../images/gallery-food/images";
import vineImages from "../../images/gallery-vine/images";
import natureImages from "../../images/gallery-nature/images";
import cultureImages from "../../images/gallery-culture/images";

function GalleryDesctop() {
  const [activeCategory, setActiveCategory] = useState("еда");

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const categoryContent = {
    еда: "Волшебные виды, аутентичная кухня и кавказское гостеприимство",
    вино: "Ощутить вкус кавказской жизни, от обрезки лоз до дегустации вин",
    культура: "Откройте для себя самобытную и уникальную культуру Кавказа",
    природа: "Волшебные виды и кавказское гостеприимство в чистом виде",
  };

  const categoryImages = {
    еда: foodImages,
    вино: vineImages,
    культура: cultureImages,
    природа: natureImages,
  };

  return (
    <section className="gallery-desctop">
      <div className={`gallery__header gallery__header-${activeCategory}`}>
        <HeaderBar />
        <div className="gallery-desctop__text-container">
          <h1 className="gallery__title-desctop">
					{categoryContent[activeCategory]}
          </h1>
          <h2 className="gallery__subtitle-desctop">СЕВЕРНЫЙ КАВКАЗ</h2>
        </div>
        <h3 className="promo__tour-text promo__nature">ПУТЕШЕСТВУЙТЕ С НАМИ</h3>
      </div>

      <div className="gallery__desctop-container">
        <div className="gallery__buttons-bar-desctop">
          {["еда", "вино", "культура", "природа"].map((category) => (
            <button
              key={category}
              className={`gallery__button-desctop ${
                activeCategory === category
                  ? "gallery__button-active-desctop"
                  : ""
              }`}
              onClick={() => handleCategoryChange(category)}
            >
              {category[0].toUpperCase() + category.slice(1)}
            </button>
          ))}
        </div>
        <div className="gallery__grid-desctop">
          {categoryImages[activeCategory].map((image, index) => (
            <img
              key={index}
              className="gallery__image-desctop"
              src={image}
              alt={`${activeCategory} image ${index + 1}`}
            />
          ))}
        </div>
        <div className="gallery__button-container-desctop">
          <Link to="/payment" className="gallery__button-buy-desctop">
            Купить тур
          </Link>
        </div>
      </div>
    </section>
  );
}

export default GalleryDesctop;
