import React from "react";
import { useMenu } from "../../../context/MenuContext";
import "./FAQ.css";
import HeaderBar from "../../Header/HeaderBar/HeaderBar";
import Footer from "../../Footer/Footer";

function FAQ() {
  const { isMenuOpen, handleBurgerClick, handleButtonClick } = useMenu();
  return (
    <section className="faq">
      <HeaderBar
        isMenuOpen={isMenuOpen}
        handleBurgerClick={handleBurgerClick}
        handleButtonClick={handleButtonClick}
      />
      <div className="faq__container">
        <div className="faq__contact-content">
          <h1 className="faq__title">Часто Задаваемые Вопросы</h1>
          <p className="faq__text">
            Не можете найти ответ? Напишите или позвоните нам
          </p>
          <p className="faq__text">gas.travel@yandex.ru</p>
          <p className="faq__text">+7 915 432.15.56</p>
        </div>

        <div className="faq__cards">
          <div className="faq__card">
            <h2 className="faq__card-title">
              Рекомендуемое время прибытия и отъезда
            </h2>
            <p className="faq__card-text">
              Мы рекомендуем приехать в Минеральные Воды не позднее 14:00, а
              обратный билет взять не раньше 16:00
            </p>
          </div>
          <div className="faq__card">
            <h2 className="faq__card-title">Кто обычно едет в путешествие?</h2>
            <p className="faq__card-text">
              Семейные пары, люди, путешествующие одни или с друзьями, которые
              получают удовольствие от окружающей природы и гастрономии, а также
              ценят комфорт и внимание к деталям
            </p>
          </div>
          <div className="faq__card">
            <h2 className="faq__card-title">
              Будет ли мне комфортно, если я еду в один?
            </h2>
            <p className="faq__card-text">
              Да. Мы уже нашли для Вас компанию в путешествие. Многие решаются
              отправиться в гастрономическое приключение с нами, не найдя
              компанию. Вы найдете себе новых друзей и единомышленников, ведь
              ничто так не сближает, как неформальная и дружеская атмосфера
            </p>
          </div>
          <div className="faq__card">
            <h2 className="faq__card-title">
              Можно ли взять в гастро тур ребёнка и будет ли ему интересно?
            </h2>
            <p className="faq__card-text">
              Да. Программа нашего путешествия включает не только дегустации, но
              и знакомство с достопримечательностями, традицией, культурой и
              кухней региона. Единственное ограничение: возраст детей должен
              быть не менее 12 лет
            </p>
          </div>
          <div className="faq__card">
            <h2 className="faq__card-title">
              Можно ли ознакомиться с условиями договора заранее?
            </h2>
            <p className="faq__card-text">
              Да, Вы можете позвонить нам или написать, и мы вышлем Вам договор
              для ознакомления, а также ответим на все интересующие Вас вопросы
            </p>
          </div>
          <div className="faq__card">
            <h2 className="faq__card-title">Что входит в стоимость тура?</h2>
            <p className="faq__card-text">
              В стоимость уже включено всё питание: дегустации, завтраки, обеды
              и ужины в ресторанах, указанных в программе путешествия.
              Транспорт, трансфер из и в аэропорт /жд вокзал, отели, гиды,
              экскурсии, входные билеты, также входят в стоимость. У нас нет
              скрытых платежей и комиссий, вся информация указана в договоре при
              бронировании
            </p>
          </div>
          <div className="faq__card">
            <h2 className="faq__card-title">Что не входит в стоимость тура?</h2>
            <p className="faq__card-text">
              В стоимость тура не включён перелёт, курортный сбор и личные
              расходы
            </p>
          </div>
          <div className="faq__card">
            <h2 className="faq__card-title">Где встречаемся и откуда вылет?</h2>
            <p className="faq__card-text">
              Наши путешественники приезжают из разных регионов страны, поэтому
              в стоимость не включен перелет к месту начала путешествия, но мы
              обязательно встретим и проводим Вас в аэропорт или на
              железнодорожный вокзал
            </p>
          </div>
          <div className="faq__card">
            <h2 className="faq__card-title">Где мы будем жить?</h2>
            <p className="faq__card-text">
              Мы не экономим на проживании и бронируем номера в лучших отелях
              региона не ниже 4*
            </p>
          </div>
          <div className="faq__card">
            <h2 className="faq__card-title">Как оплатить тур?</h2>
            <p className="faq__card-text">
              Очень просто. Вы выбираете дату поездки, количество
              путешественников, а мы присылаем Вам QR-код с нашими реквизитами,
              по которому можно оплатить в приложении Вашего банка, введя сумму
              в соответствии с количеством путешественников. После оплаты, мы
              пришлем Вам подтверждение об оплате и договор о бронировании в
              котором закреплена вся программа путешествия
            </p>
          </div>
          <div className="faq__card">
            <h2 className="faq__card-title">
              Если я оплачу тур, а группа не наберётся?
            </h2>
            <p className="faq__card-text">
              Путешествие состоится вне зависимости от количества человек в
              группе. Вы не потеряете вложенные средства, отпуск не нужно будет
              переносить
            </p>
          </div>
          <div className="faq__card">
            <h2 className="faq__card-title">
              Будет ли у свободное время во время тура?
            </h2>
            <p className="faq__card-text last">
              Да, у Вас будет время для самостоятельного изучения городов
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default FAQ;
