import React from "react";
import { useNavigate } from "react-router-dom";

function NavigateButton({ buttonText, destination = "/tours", onClick }) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Если передан внешний обработчик, вызови его
    if (onClick) {
      onClick();
    }
    // Перенаправление на страницу туров
    navigate(destination);
  };

  return (
    <button type="button" className="promo__big-button" onClick={handleButtonClick}>
      <span>{buttonText}</span>
    </button>
  );
}

export default NavigateButton;