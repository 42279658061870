import React, { useEffect, useState, useRef } from "react";
import "./Schedule.css";
import Hotels from "../Hotels/Hotels";
import Price from "../Price/Price";

import { Link } from "react-router-dom";

function Schedule() {
  const [activeButton, setActiveButton] = useState("route");
  const routeRef = useRef(null);
  const hotelRef = useRef(null);
  const priceRef = useRef(null);
  const handleScrollTo = (section) => {
    setActiveButton(section);
    if (section === "route" && routeRef.current) {
      routeRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "hotel" && hotelRef.current) {
      hotelRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "price" && priceRef.current) {
      priceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="schedule">
      <div className="schedule__bar">
        <button
          className={`shedule__button schedule__route ${
            activeButton === "route" ? "active-btn" : ""
          }`}
          onClick={() => handleScrollTo("route")}
        >
          Маршрут
        </button>
        <button
          className={`shedule__button schedule__hotel ${
            activeButton === "hotel" ? "active-btn" : ""
          }`}
          onClick={() => handleScrollTo("hotel")}
        >
          Отель
        </button>
        <button
          className={`shedule__button schedule__price ${
            activeButton === "price" ? "active-btn" : ""
          }`}
          onClick={() => handleScrollTo("price")}
        >
          Стоимость
        </button>
      </div>

      <div className="schedule__content">
        {/* DAY 1 */}
        <section className="scheduale__day day1">
          <h2 className="schedule__title">1 ДЕНЬ</h2>

          <div className="schedule__image-container">
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/prT6Vbrt/1.png"}
              alt="картинка"
            />
          </div>

          <div className="schedule__text-container">
            {/* Секция 1: ВСТРЕЧА */}
            <p className="schedule__text">
              Встречаем Вас в аэропорту или на вокзале Минеральных Вод.
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/RFvY8nzh/2.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              Вечером совершим небольшую прогулку по Курортному бульвару
              Кисловодска, увидим исторические здания, Нарзанную галерею в стиле
              рыцарского замка и здание главных Нарзанных ванн, построенных в
              мавританском стиле. Выезд из отеля около 17:00
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/MTtL8KWD/3.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              На ужин заглянем в самое сердце курорта, насладимся авторской
              интерпретацией знакомых ингредиентов с необычными
              гастро—акцентами, сырами из собственной сыроварни, настойками
              собственного производства и вином
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/rp1bQN37/4.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              После ужина едем отдыхать в наш отель в Ессентуках.
            </p>
          </div>
        </section>

        {/* DAY 2} */}
        <section className="scheduale__day day2">
          <h2 className="schedule__title">2 ДЕНЬ</h2>

          <div className="schedule__image-container">
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/0jBHbWKK/5.png"}
              alt="картинка"
            />
          </div>

          <div className="schedule__text-container">
            <p className="schedule__text">
              Завтракаем и отправляемся на гору Машук, с которой открывается
              захватывающая дух панорама предгорий Кавказа и Большого
              Кавказского хребта с Эльбрусом и Казбеком, а также панорама
              Пятигорска и его окрестностей. Прогуляемся Пятигорску, узнаем о
              живших здесь знаменитых людях, посетим бюветы Пятигорска, где
              попробуем минеральную воду, а также знаменитый Провал и место
              дуэли Лермонтова. Вы думали, что знаете о Пятигорске все? Наш гид
              сможет Вас удивить
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/RCsYsFpC/6.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              На обед мы едем в местную сыроварню, где для нас проведут
              дегустацию сыров под бокал вина. Мы попробуем фондю с хлебными
              гренками, фруктами и миксом закусок, пиццу с нестандартными
              сочетаниями, а также крамбл с кремом из рикотты
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/c4vbVSPR/7.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              Далее мы посетим городскую винодельню с высококачественным
              оборудованием, позволяющим оптимально обрабатывать виноград при
              сохранении его целостности. В 2012—2016 годах первые произведенные
              здесь вина завоевали медали на конкурсе «Кубок Гаражистов». Мы
              познакомимся с владелицей винодельни, которая проведет для нас
              дегустацию вин из винограда сортов Каберне Фран, Каберне Совиньон
              и Саперави
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/Gh65Rf1Z/8.png"}
              alt="картинка"
            />
            <p className="schedule__text">
              На ужин отправимся во времена Лермонтова, где окунемся в мир живой
              музыки и насладимся старорусской кухней, попробуем перепелку с
              ореховой посыпкой и чечевичным пюре,гусарскую кашу, пирожки «с
              опилками» по рецепту 19 века и гусарскую жженку — напиток, которым
              когда-то отмечали посвящение в гусары. Возвращаемся в наш отель и
              отдыхаем
            </p>
          </div>
        </section>

        {/* {DAY3} */}

        <section className="scheduale__day day3">
          <h2 className="schedule__title">3 ДЕНЬ</h2>

          <div className="schedule__image-container">
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/T3mH3cdh/9.png"}
              alt="картинка"
            />
          </div>

          <div className="schedule__text-container">
            <p className="schedule__text">
              Завтрак и экскурсия по Ессентукам, где во время прогулки по
              тенистым аллеям парка услышим увлекательные истории связанные с
              городом, узнаем, что про механотерапию, попробуем минеральную воду
              и посетим знаменитую грязелечебницу Семашко
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/CKgrBf57/10.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              Насладимся обедом в местном ресторане с панорамным видом на город
              и попробуем вкуснейшие хычины
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/cJB9Q48S/11.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              Далее едем на одно из крупнейших винодельческих предприятий
              Ставрополья с более чем вековой историей, где нас познакомят с
              историей завода, проведут по старинным подвалам, хранящим на
              выдержке благородные напитки и поделятся секретами производства.
              Мы попробуем коньяки и вина, часть из которых обычно не подаются
              на дегустациях
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/sXfnKdpZ/12.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              Вечер завершим ужином в ресторане—пивоварне, попробуем несколько
              сортов пива, сваренного по классическим чешским рецептам и,
              конечно, фирменные пивные закуски
            </p>
          </div>
        </section>

        {/* {DAY4} */}

        <section className="scheduale__day day4">
          <h2 className="schedule__title">4 ДЕНЬ</h2>

          <div className="schedule__image-container">
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/ZRfwM7cs/13.png"}
              alt="картинка"
            />
          </div>

          <div className="schedule__text-container">
            <p className="schedule__text">
              После завтрака едем в семью, где познакомимся с бытом и историей
              донских казаков, проживших около 250 лет на чужбине.
              Казаки–Некрасовцы являются удивительным сплавом старообрядческих
              русских обычаев и османского колорита. Интересно, что этот особый
              восточный колорит сохраняется несмотря на то, что они вернулись на
              Родину более полвека назад. Мы узнаем, почему некрасовцы не
              возвращались в царскую Россию, зачем кофе нужно подавать в
              постель, поиграем в казачий боулинг, а также отведаем традиционные
              блюда казаков по рецептам 15 –17 веков под наливки и настойки
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/prpsbfcW/14.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              Узнав все секреты казаков, мы отправимся на левый берег реки Кума
              в предгорья Северного Кавказа на семейную винодельню. Сама
              винодельня и хранилище вин довольно компактные, но они расположены
              в старинном здании с винным погребом, построенным в 19 веке
              немецкими колонистами. Виноградники расположены на 45-й параллели
              – той же широте, на которой находятся знаменитые винные регионы
              Бордо, Венето и Пьемонт, а земля соответствует категории Гран Крю
              (превосходный участок). Мы увидим все процессы производства,
              соответствующие современным требованиям виноделия, а завершим наш
              вечер дегустацией авторских вин
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/BbkMqjRx/15.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              После экскурсии заглянем в винотеку и попробуем продукцию
              старейшего завода настоек, бальзамов, ароматных спиртов и водок,
              история которого начинается с 1868 года. Нам расскажут об истории
              завода, а также проведут дегустацию крепких спиртных напитков
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/WpH9myth/16.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              После виноделен поужинаем в атмосферном ресторане кавказской кухни
              с интересной историей и традицией
            </p>
          </div>
        </section>

        {/* {DAY5} */}
        <section className="scheduale__day day5">
          <h2 className="schedule__title">5 ДЕНЬ</h2>

          <div className="schedule__image-container">
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/bNSmmR2L/17.png"}
              alt="картинка"
            />
          </div>

          <div className="schedule__text-container">
            <p className="schedule__text">
              Едем к природному памятнику — скальное образование на окраине
              Кисловодска. Он представляет собой мыс одного из отрогов
              Боргустанского хребта, абсолютная высота которого 871 м. В
              результате выветривания и эрозии крайняя пещера мыса стала
              сквозной и по форме напоминает гигантское «кольцо» диаметром 8–10
              м. Отсюда открывается прекрасная панорама Кисловодска и его
              окрестностей, Пятигорья, Джинальского хребта, а в хорошую погоду
              виден и Эльбрус. Нас ждет захватывающая экскурсия по природным
              красотам
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/8z8t9rRG/18.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              Далее нас ждет красивое горное ущелье в окружении доломитовых
              пещер, отвесных скал и живописных лугов. Водопады буквально
              спрятаны в узкой теснине реки, которая каньоном рассекает
              Скалистый хребет Северного Кавказа. Насладимся кавказским
              гостеприимством и отведаем хычины, жар—баур, шашлык из черного
              карачаевского барашка и местное живое пиво с видом на горы
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/K8nJ2vfM/19.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              Зарядившись позитивными эмоциями и впечатления, вернемся обратно в
              Кисловодск, город—курорт, известный еще с царских времен.
              Прогуляемся по терренкуру национального парка —главной
              достопримечательности города, увидим старинную застройку центра
              города и знаменитую колоннаду
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/1zsM5h7Y/20.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              На ужин заглянем в настоящий сказочный лес с цветочной рекой и
              скалистыми стенами, где познакомимся с кухней Кавказа в новой
              интерпретации и удивимся оригинальной подаче напитков и блюд
            </p>
          </div>
        </section>

        <section className="scheduale__day day6">
          <h2 className="schedule__title">6 ДЕНЬ</h2>

          <div className="schedule__image-container">
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/7L995spP/21.png"}
              alt="картинка"
            />
          </div>

          <div className="schedule__text-container">
            <p className="schedule__text">
              Экскурсия в самый маленький из городов КавМинВод, который
              расположен в лесном массиве. Мы узнаем, как складывалась история
              курорта, про дачу эмира Бухары, Лермонтовский, Славяновский и
              Смирновский бюветы
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/3RwBmjTX/22.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              Пообедаем в ресторане на Курортном озере в сопровождении вин и
              настоек. Завершим обед фирменным десертом, вкус и вид которого
              никого не оставит равнодушным
            </p>
            <img
              className="schedule__image"
              src={"https://i.postimg.cc/gJBKDwzf/23.png"}
              alt="картинка"
            />

            <p className="schedule__text">
              Провожаем Вас в аэропорт или на железнодорожный вокзал Минеральных
              Вод. И будем ждать Вас в новом путешествии
            </p>
          </div>
        </section>
      </div>
      <div className="shedule__hotels" ref={hotelRef}>
        <Hotels />
      </div>
      <div className="shedule__price" ref={priceRef}>
        <Price />
      </div>
      <div className="schedule__other-question">
        <h3 className="schedule__other-question-title">
          У ВАС ОСТАЛИСЬ ЕЩЁ ВОПРОСЫ?
        </h3>
        <Link
          to="/faq"
          target="blank"
          className="schedule__other-question-button"
        >
          Задать
        </Link>
      </div>
    </section>
  );
}

export default Schedule;
