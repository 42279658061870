import React from "react";
import "./Partnership.css";
import "../../Contacts/Contacts.css";
import { useMenu } from "../../../context/MenuContext";
import "./Partnership.css";
import HeaderBar from "../../Header/HeaderBar/HeaderBar";
import NavigateButton from "../../NavigateButton/NavigateButton";
import telegram from "../../../images/tg.svg";
import instagram from "../../../images/Instagram.svg";
import whatsapp from "../../../images/whatsapp.svg";
import { Link } from "react-router-dom";

function Partnership() {
  const { isMenuOpen, handleBurgerClick, handleButtonClick } = useMenu();
  return (
    <section className="partnership">
      <HeaderBar
        isMenuOpen={isMenuOpen}
        handleBurgerClick={handleBurgerClick}
        handleButtonClick={handleButtonClick}
      />
      <div className="contacts__container">
        <div className="contacts__text-container">
          <h1 className="contacts__title">СОТРУДНИЧЕСТВО</h1>
          <span className="contacts__span">НАШИМ ПАРТНЁРАМ</span>
          <p className="contacts__text">
            Gastro Traveller ищет увлеченных людей, которые создают аутентичные
            региональные продукты и готовы пригласить к себе в гости на
            дегустацию, проводят нетривиальные мастер классы на территории
            Кавказских Минеральных Вод
          </p>
          <p className="contacts__text">
            Давайте работать вместе, чтобы открыть с неожиданной стороны
            Северный Кавказ для наших гостей
          </p>
          <p className="contacts__text middle-text">gas.travel@yandex.ru</p>
          <p className="contacts__text">+7 916 940.06.91</p>
          <div className="contacts__content__links">
            <div className="contacts__card">
              <h3 className="contacts__card-title">Поддержка</h3>
              <p className="contacts__card-text">
                Наша служба поддержки доступна в любое время, чтобы решить ваши
                проблемы или ответить на интересующие вас вопросы
              </p>
              <a href="./" className="contacts__social-link" target="blank">
                <img
                  className="contacts__social-icon"
                  src={whatsapp}
                  alt="whatsapp"
                />
              </a>
            </div>
            <div className="contacts__card">
              <h3 className="contacts__card-title">Предложения</h3>
              <p className="contacts__card-text">
                Мы ценим ваше мнение и постоянно работаем над улучшением
                программы туров и разработку новых направлений
              </p>
              <a href="./" className="contacts__social-link" target="blank">
                <img
                  className="contacts__social-icon"
                  src={telegram}
                  alt="telegram"
                />
              </a>
            </div>
            <div className="contacts__card">
              <h3 className="contacts__card-title">СМИ</h3>
              <p className="contacts__card-text t1">
                Если у вас есть вопросы и предложения по сотрудничеству,
                свяжитесь с нами по адресу gas.travel@yandex.ru
              </p>
              <a href="./" className="contacts__social-link" target="blank">
                <img
                  className="contacts__social-icon"
                  src={instagram}
                  alt="instagram"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="partnership__form-container">
          <h2 className="contacts__subtitle">Связаться с Нами</h2>
          <p className="contacts__paragraph">Заполните форму обратной связи</p>
          <form className="contacts__form">
            <label className="contacts__label">Имя</label>
            <input
              className="contacts__input"
              type="text"
              name="name"
              id="name"
              minLength="2"
              maxLength="40"
              required
            />
            <label className="contacts__label">Почта</label>
            <input
              className="contacts__input"
              type="email"
              name="email"
              id="email"
              required
            />
            <label className="contacts__label">Телефон</label>
            <input
              className="contacts__input"
              type="phone"
              name="number"
              id="number"
              required
            />
            <label className="contacts__label">Сообщение</label>
            <textarea className="contacts__textarea" type="text" />
            <div className="contacts__checkbox">
              <input className="contacts__input-checkbox" type="checkbox" />
              <label className="contacts__checkbox-label">
                Я прочитал и принимаю
                <Link to="/privacy-policy" className="contacts__link">
                  Политику конфиденциальности
                </Link>
              </label>
            </div>
            <NavigateButton buttonText="Отправить" />
          </form>
        </div>
      </div>
    </section>
  );
}

export default Partnership;
