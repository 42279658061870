import React from "react";
import { useMenu } from "../../../context/MenuContext";
import "../Information.css";
import HeaderBar from "../../Header/HeaderBar/HeaderBar";
import Footer from "../../Footer/Footer";

function Terms() {
  const { isMenuOpen, handleBurgerClick, handleButtonClick } = useMenu();

  return (
    <section className="information">
      <HeaderBar
        isMenuOpen={isMenuOpen}
        handleBurgerClick={handleBurgerClick}
        handleButtonClick={handleButtonClick}
      />
      <h1 className="information__title">Политика Конфиденциальности</h1>
      <div className="information__container">
        <p className="information__text">
          Настоящая Политика конфиденциальности персональных данных (далее –
          Политика конфиденциальности) действует в отношении всей информации,
          которую данный сайт, на котором размещен текст этой Политики
          конфиденциальности, может получить о Пользователе, а также любых
          программ и продуктов, размещенных на нем.
        </p>

        <p className="information__text">
          1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ 1.1. В настоящей Политике конфиденциальности
          используются следующие термины: 1.1.1. «Администрация сайта» —
          уполномоченные сотрудники на управления сайтом, действующие от его
          имени, которые организуют и (или) осуществляют обработку персональных
          данных, а также определяют цели обработки персональных данных, состав
          персональных данных, подлежащих обработке, действия (операции),
          совершаемые с персональными данными. 1.1.2. «Персональные данные» —
          любая информация, относящаяся к прямо или косвенно определенному, или
          определяемому физическому лицу (субъекту персональных данных). 1.1.3.
          «Обработка персональных данных» — любое действие (операция) или
          совокупность действий (операций), совершаемых с использованием средств
          автоматизации или без использования таких средств с персональными
          данными, включая сбор, запись, систематизацию, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование, передачу
          (распространение, предоставление, доступ), обезличивание,
          блокирование, удаление, уничтожение персональных данных, в
          соответствии с Федеральным законом «О персональных данных» от
          27.07.2006г. №152-ФЗ. 1.1.4. «Конфиденциальность персональных данных»
          — обязательное для соблюдения Администрацией сайта требование не
          допускать их умышленного распространения без согласия субъекта
          персональных данных или наличия иного законного основания. 1.1.5.
          «Пользователь сайта (далее Пользователь)» – лицо, имеющее доступ к
          сайту, посредством сети Интернет и использующее данный сайт для своих
          целей. 1.1.6. «Cookies» — небольшой фрагмент данных, отправленный
          веб-сервером и хранимый на компьютере пользователя, который веб-клиент
          или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при
          попытке открыть страницу соответствующего сайта. 1.1.7. «IP-адрес» —
          уникальный сетевой адрес узла в компьютерной сети, построенной по
          протоколу IP.
        </p>
        <p className="information__text">
          2. ОБЩИЕ ПОЛОЖЕНИЯ 2.1. Использование Пользователем сайта означает
          согласие с настоящей Политикой конфиденциальности и условиями
          обработки персональных данных Пользователя. 2.2. В случае несогласия с
          условиями Политики конфиденциальности Пользователь должен прекратить
          использование сайта. 2.3. Настоящая Политика конфиденциальности
          применяется только к данному сайту. Администрация сайта не
          контролирует и не несет ответственность за сайты третьих лиц, на
          которые Пользователь может перейти по ссылкам, доступным на данном
          сайте. 2.4. Администрация сайта не проверяет достоверность
          персональных данных, предоставляемых Пользователем сайта.
        </p>
        <p className="information__text">
          3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ 3.1. Настоящая Политика
          конфиденциальности устанавливает обязательства Администрации сайта по
          умышленному неразглашению персональных данных, которые Пользователь
          предоставляет по разнообразным запросам Администрации сайта (например,
          при регистрации на сайте, оформлении заявки, подписки на уведомления и
          т.п). 3.2. Персональные данные, разрешённые к обработке в рамках
          настоящей Политики конфиденциальности, предоставляются Пользователем
          путём заполнения специальных форм на Сайте и обычно включают в себя
          следующую информацию: 3.2.1. фамилию, имя, отчество Пользователя
          3.2.2. контактный телефон Пользователя 3.2.3. адрес электронной почты
          (e-mail) 3.2.4. место жительство Пользователя и другие данные. 3.3.
          Администрация сайта также принимает усилия по защите Персональных
          данных, которые автоматически передаются в процессе посещения страниц
          сайта: IP адрес; информация из cookies; информация о браузере (или
          иной программе, которая осуществляет доступ к сайту); время доступа;
          посещенные адреса страниц; реферер (адрес предыдущей страницы) и т.п.
          3.3.1. Отключение cookies может повлечь невозможность доступа к сайту.
          3.3.2. Сайт осуществляет сбор статистики об IP-адресах своих
          посетителей. Данная информация используется с целью выявления и
          решения технических проблем, для контроля корректности проводимых
          операций. 3.4. Любая иная персональная информация неоговоренная выше
          (история покупок, используемые браузеры и операционные системы и т.д.)
          не подлежит умышленному разглашению, за исключением случаев,
          предусмотренных в п.п. 5.2. и 5.3. настоящей Политики
          конфиденциальности.
        </p>
        <p className="information__text">
          4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ 4.1. Персональные
          данные Пользователя Администрация сайта может использовать в целях:
          4.1.1. Идентификации Пользователя, зарегистрированного на сайте, для
          оформления заявки и (или) заключения Договора. 4.1.2. Предоставления
          Пользователю доступа к персонализированным ресурсам сайта. 4.1.3.
          Установления с Пользователем обратной связи, включая направление
          уведомлений, запросов, касающихся использования сайта, оказания услуг,
          обработка запросов и заявок от Пользователя. 4.1.4. Определения места
          нахождения Пользователя для обеспечения безопасности, предотвращения
          мошенничества. 4.1.5. Подтверждения достоверности и полноты
          персональных данных, предоставленных Пользователем. 4.1.6. Создания
          учетной записи для совершения действий, направленных на оформление
          заявки, заключения договора, иных действий, связанных с сайтом, если
          Пользователь дал согласие на создание учетной записи. 4.1.7.
          Уведомления Пользователя сайта о состоянии заявки. 4.1.8. Обработки и
          получения платежей, подтверждения налога или налоговых льгот,
          оспаривания платежа. 4.1.9. Предоставления Пользователю эффективной
          клиентской и технической поддержки при возникновении проблем,
          связанных с использованием сайта. 4.1.10. Предоставления Пользователю
          с его согласия, обновлений продукции, специальных предложений,
          информации о ценах, новостной рассылки и иных сведений от имени сайта
          или от имени партнеров сайта. 4.1.11. Осуществления рекламной
          деятельности с согласия Пользователя. 4.1.12. Предоставления доступа
          Пользователю на сторонние сайты или сервисы партнеров данного сайта с
          целью получения их предложений, обновлений или услуг.
        </p>
        <p className="information__text">
          5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ 5.1. Обработка
          персональных данных Пользователя осуществляется без ограничения срока,
          любым законным способом, в том числе в информационных системах
          персональных данных с использованием средств автоматизации или без
          использования таких средств. 5.2. Пользователь соглашается с тем, что
          Администрация сайта вправе передавать персональные данные третьим
          лицам, в частности, курьерским службам, организациями почтовой связи,
          операторам электросвязи, исключительно в целях выполнения заявок
          Пользователя, оформленных на сайте, в рамках Договора публичной
          оферты. 5.3. Персональные данные Пользователя могут быть переданы
          уполномоченным органам государственной власти только по основаниям и в
          порядке, установленным действующим законодательством.
        </p>
        <p className="information__text">
          6. ОБЯЗАТЕЛЬСТВА СТОРОН 6.1. Пользователь обязуется: 6.1.1.
          Предоставить корректную и правдивую информацию о персональных данных,
          необходимую для пользования сайтом. 6.1.2. Обновить или дополнить
          предоставленную информацию о персональных данных в случае изменения
          данной информации. 6.1.3. Принимать меры для защиты доступа к своим
          конфиденциальным данным, хранящимся на сайте. 6.2. Администрация сайта
          обязуется: 6.2.1. Использовать полученную информацию исключительно для
          целей, указанных в п. 4 настоящей Политики конфиденциальности. 6.2.2.
          Не разглашать персональных данных Пользователя, за исключением п.п.
          5.2. и 5.3. настоящей Политики Конфиденциальности. 6.2.3. Осуществить
          блокирование персональных данных, относящихся к соответствующему
          Пользователю, с момента письменного обращения или письменного запроса
          Пользователя, или его законного представителя либо уполномоченного
          органа по защите прав субъектов персональных данных на период
          проверки, в случае выявления недостоверных персональных данных или
          неправомерных действий.
        </p>
        <p className="information__text">
          7. ОТВЕТСТВЕННОСТЬ СТОРОН 7.1. Администрация сайта несёт
          ответственность за умышленное разглашение Персональных данных
          Пользователя в соответствии с действующим законодательством, за
          исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей
          Политики Конфиденциальности. 7.2. В случае утраты или разглашения
          Персональных данных Администрация сайта не несёт ответственность, если
          данная конфиденциальная информация: 7.2.1. Стала публичным достоянием
          до её утраты или разглашения. 7.2.2. Была получена от третьей стороны
          до момента её получения Администрацией сайта. 7.2.3. Была получена
          третьими лицами путем несанкционированного доступа к файлам сайта.
          7.2.4. Была разглашена с согласия Пользователя. 7.3. Пользователь
          несет ответственность за правомерность, корректность и правдивость
          предоставленной Персональных данных в соответствии с действующим
          законодательством.
        </p>
        <p className="information__text">
          8. РАЗРЕШЕНИЕ СПОРОВ 8.1. До обращения в суд с иском по спорам,
          возникающим из отношений между Пользователем сайта и Администрацией
          сайта, обязательным является предъявление претензии (письменного
          предложения о добровольном урегулировании спора). 8.2. Получатель
          претензии в течение 30 календарных дней со дня получения претензии,
          письменно уведомляет заявителя претензии о результатах рассмотрения
          претензии. 8.3. При не достижении соглашения спор будет передан на
          рассмотрение в судебный орган в соответствии с действующим
          законодательством. 8.4. К настоящей Политике конфиденциальности и
          отношениям между Пользователем и Администрацией сайта применяется
          действующее законодательство.
        </p>
        <p className="information__text">
          9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ 9.1. Администрация сайта вправе вносить
          изменения в настоящую Политику конфиденциальности без согласия
          Пользователя. 9.2. Новая Политика конфиденциальности вступает в силу с
          момента ее размещения на Сайте, если иное не предусмотрено новой
          редакцией Политики конфиденциальности.
        </p>
      </div>
      <Footer />
    </section>
  );
}

export default Terms;
