import React from "react";
import { useMenu } from "../../../context/MenuContext";
import { Link, useLocation } from "react-router-dom";
import BurgerButton from "../../BurgerButton/BurgerButton";
import Menu from "../../Menu/Menu";

const HeaderBar = () => {
  const { isMenuOpen, handleBurgerClick } = useMenu();
  const location = useLocation();
  const isTourPage = location.pathname === "/tours";
  const isGalleryPage = location.pathname === "/gallery";
  const isContactsPage = location.pathname === "/contacts";
  const isThanksPage = location.pathname === "/thanks";

  const informationPages = ["/faq", "/terms", "/privacy-policy", "/payment"];
  const isInformationPages = informationPages.includes(location.pathname);

  return (
    <div className="header__bar">
      <Link
        to="/"
        className={`header__logo 
				${isInformationPages ? "header__logo-black" : ""}
				${isThanksPage ? "header__button-black" : ""}
				`}
      >
        Gastro Traveller
      </Link>

      <div className="header__buttons-bar">
        <Link
          to="/tours"
          className={`header__button button1 
    ${isInformationPages ? "header__button-black" : ""} 
		${isThanksPage ? "header__button-black" : ""}
    ${isTourPage ? "active-tour" : ""}`}
        >
          Туры
        </Link>
        <Link
          to="/gallery"
          className={`header__button button1 
    ${isInformationPages ? "header__button-black" : ""} 
		${isThanksPage ? "header__button-black" : ""}
    ${isGalleryPage ? "active-gallery" : ""}`}
        >
          Галерея
        </Link>
        <Link
          to="/contacts"
          className={`header__button button1 
    ${isInformationPages ? "header__button-black" : ""} 
		${isThanksPage ? "header__button-black" : ""} 
    ${isContactsPage ? "active-contacts" : ""}`}
        >
          Контакты
        </Link>
      </div>

      <BurgerButton
        handleClick={handleBurgerClick}
        isOpen={isMenuOpen}
        className="header__burger"
        style={{ border: "none" }}
      />

      <Menu isMenuOpen={isMenuOpen} handleBurgerClick={handleBurgerClick} />

      {/* <span
        className={`header__search ${
          isInformationPages ? "header__search-black" : ""
        }
				${isThanksPage ? "header__button-black" : ""}
				`}
      >
        Поиск
      </span> */}
    </div>
  );
};

export default HeaderBar;
