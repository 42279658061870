import React, { createContext, useState, useContext } from "react";

const MenuContext = createContext();

// Хук для доступа к контексту
export const useMenu = () => {
  return useContext(MenuContext);
};

// Провайдер, который будет оборачивать компоненты
export const MenuProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleBurgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <MenuContext.Provider value={{ isMenuOpen, handleBurgerClick }}>
      {children}
    </MenuContext.Provider>
  );
};


