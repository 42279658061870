import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMenu } from "../../context/MenuContext";
import "./Header.css";
import HeaderBar from "./HeaderBar/HeaderBar";
import Promo from "../Promo/Promo";

const Header = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = location.pathname === "/";
  const isTourPage = location.pathname === "/tours";
  const isGalleryPage = location.pathname === "/gallery";
  const isContactsPage = location.pathname === "/contacts";
  const isThanksPage = location.pathname === "/thanks";

  const handleButtonClick = (path) => {
    navigate(path);
  };

  const headerClass = isTourPage
    ? "header__tour"
    : isGalleryPage
    ? "header__gallery"
    : isContactsPage
    ? "header__contacts"
    : "header";

  const { isMenuOpen, handleBurgerClick } = useMenu();

  return (
    <header className={headerClass}>
      <HeaderBar
        isMenuOpen={isMenuOpen}
        handleBurgerClick={handleBurgerClick}
        handleButtonClick={handleButtonClick}
      />
      <Promo />
    </header>
  );
};

export default Header;
