// src/images/gallery-food/images.js
import food1 from "./food1-min.png";
import food2 from "./food3-min.png";
import food3 from "./food5-min.png";
import food4 from "./food7-min.png";
import food5 from "./food9-min.png";
import food6 from "./food11-min.png";
import food7 from "./food13-min.png";
import food8 from "./food15-min.png";
import food9 from "./food17-min.png";
import food10 from "./food2-min.png";
import food11 from "./food4-min.png";
import food12 from "./food6-min.png";
import food13 from "./food8-min.png";
import food14 from "./food10-min.png";
import food15 from "./food12-min.png";
import food16 from "./food14-min.png";
import food17 from "./food16-min.png";
import food18 from "./food18-min.png";

// Экспортируем массив с изображениями
const foodImages = [
  food1, food2, food3, food4, food5, food6, food7, food8, food9,
  food10, food11, food12, food13, food14, food15, food16, food17, food18
];

export default foodImages;