// src/images/gallery-vine/images.js
import vine1 from "./vine1.png";
import vine2 from "./vine2.png";
import vine3 from "./vine3.png";
import vine4 from "./vine4.png";
import vine5 from "./vine5.png";
import vine6 from "./vine6.png";
import vine7 from "./vine7.png";
import vine8 from "./vine8.png";
import vine9 from "./vine9.png";
import vine10 from "./vine10.png";
import vine11 from "./vine11.png";
import vine12 from "./vine12.png";
import vine13 from "./vine13.png";
import vine14 from "./vine14.png";
import vine15 from "./vine15.png";
import vine16 from "./vine16.png";
import vine17 from "./vine17.png";
import vine18 from "./vine18.png";

// Экспортируем массив с изображениями
const vineImages = [
  vine1, vine2, vine3, vine4, vine5, vine6, vine7, vine8, vine9,
  vine10, vine11, vine12, vine13, vine14, vine15, vine16, vine17, vine18
];

export default vineImages;