import React, { useState } from "react";
import "./TravelMobile.css";

function TravelMobile() {
  const [activeCard, setActiveCard] = useState(1);

  const handleButtonClick = (cardIndex) => {
    setActiveCard(cardIndex);
  };

  return (
    <section className="travel-mobile">
      <div className="travel-mobile__image">
        <h1 className="travel-mobile__title">
          НАЧНИТЕ СВОЁ ПУТЕШЕСТВИЕ С НАМИ
        </h1>
        {activeCard === 1 && (
          <div
            className="travel-image1"
            style={{ width: "100%", height: "100%",  maxHeight: 620 }}
          />
        )}
        {activeCard === 2 && (
          <div
            className="travel-image2"
            style={{ width: "100%", height: "100%",  maxHeight: 620 }}
          />
        )}
        {activeCard === 3 && (
          <div
            className="travel-image3"
            style={{ width: "100%", height: "100%",  maxHeight: 620 }}
          />
        )}
        {activeCard === 4 && (
          <div
            className="travel-image4"
            style={{ width: "100%", height: "100%",  maxHeight: 620 }}
          />
        )}
        {activeCard === 5 && (
          <div
            className="travel-image5"
            style={{ width: "100%", height: "100%",  maxHeight: 620 }}
          />
        )}
      </div>
      <div className="travel-mobile__cards">
        {activeCard === 1 && (
          <div className="trave-mobile__card travel-card1">
            <h2 className="travel-mobile__card-number">01</h2>
            <p className="travel-mobile__card-text">
              Маленькие группы до 12 человек, гарантируют комфортный отдых<br /> и
              максимум внимания к каждому
            </p>
          </div>
        )}
        {activeCard === 2 && (
          <div className="travel-mobile__card travel-card2">
            <h2 className="travel-mobile__card-number">02</h2>
            <p className="travel-mobile__card-text">
              Транспорт, отели, гиды, экскурсии, еда, напитки уже включены в
              стоимость тура, у нас нет скрытых платежей комиссий
            </p>
          </div>
        )}
        {activeCard === 3 && (
          <div className="travel-mobile__card travel-card3">
            <h2 className="travel-mobile__card-number">03</h2>
            <p className="travel-mobile__card-text">
              Мы познакомим Вас с историей региона, самыми интересными
              достопримечательностями, а также местной кухней
            </p>
          </div>
        )}
        {activeCard === 4 && (
          <div className="travel-mobile__card travel-card4">
            <h2 className="travel-mobile__card-number">04</h2>
            <p className="travel-mobile__card-text">
              Мы посетим потомков казаков, проживших 250 лет в Турции<br /> и
              познакомимся их уникальной культурой и кухней
            </p>
          </div>
        )}
        {activeCard === 5 && (
          <div className="travel-mobile__card travel-card5">
            <h2 className="travel-mobile__card-number">05</h2>
            <p className="travel-mobile__card-text">
              На протяжении всего тура Вас будет сопровождать гид. Все
              передвижения проходят на комфортном транспорте
            </p>
          </div>
        )}
      </div>
      <div className="travel-mobile__buttons">
        <button
          className={`travel-mobile__button ${
            activeCard === 1 ? "active" : ""
          }`}
          onClick={() => handleButtonClick(1)}
        />
        <button
          className={`travel-mobile__button ${
            activeCard === 2 ? "active" : ""
          }`}
          onClick={() => handleButtonClick(2)}
        />
        <button
          className={`travel-mobile__button ${
            activeCard === 3 ? "active" : ""
          }`}
          onClick={() => handleButtonClick(3)}
        />
        <button
          className={`travel-mobile__button ${
            activeCard === 4 ? "active" : ""
          }`}
          onClick={() => handleButtonClick(4)}
        />
        <button
          className={`travel-mobile__button ${
            activeCard === 5 ? "active" : ""
          }`}
          onClick={() => handleButtonClick(5)}
        />
      </div>
    </section>
  );
}

export default TravelMobile;
