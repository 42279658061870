import React from "react";
import {useMenu} from '../../context/MenuContext'
// import "./Menu.css";
import telegram from "../../images/tg-menu.svg";
import instagram from "../../images/Instagram-menu.svg";
import whatsapp from "../../images/whatsapp-menu.svg";
import { Link } from "react-router-dom";

const Menu = () => {
	const { isMenuOpen, handleBurgerClick } = useMenu();
  return (
    <div className={`menu ${isMenuOpen ? "menu--open" : ""}`}>
      <div className="menu__bar">
        <Link to="/" className="menu__logo">
          Gastro Traveller
        </Link>
        <div className="menu__close" onClick={handleBurgerClick}>
          <span></span>
          <span></span>
        </div>
      </div>
      <nav className="menu__nav">
        <ul className="menu__list">
          <li>
            <Link
              to="/tours"
              className="menu__item"
              onClick={handleBurgerClick}
            >
              Туры
            </Link>
          </li>
          <li>
            <Link
              to="/gallery"
              className="menu__item"
              onClick={handleBurgerClick}
            >
              Галерея
            </Link>
          </li>
          <li>
            <Link
              to="/contacts"
              className="menu__item"
              onClick={handleBurgerClick}
            >
              Контакты
            </Link>
          </li>
        </ul>
      <div className="menu__footer">
        <p className="menu__copyright">© 2024 Gastro Traveller</p>
        <div className="menu__social-links">
          <a href="/" className="menu__social-link">
            <img
              className="menu__social-icon"
              src={whatsapp}
              alt="whatsapp"
            />
          </a>
          <a href="/" className="menu__social-link">
            <img
              className="menu__social-icon"
              src={telegram}
              alt="telegram"
            />
          </a>
          <a href="/" className="menu__social-link">
            <img
              className="menu__social-icon"
              src={instagram}
              alt="instagram"
            />
          </a>
        </div>
      </div>
      </nav>
    </div>
  );
};

export default Menu;
